<template>
  <div class="sidebar-cont">
    <vs-card>
      <div class="w-full" slot="header">
        <div v-if="isFirstLoad"
          class="flex items-center justify-between h-6">
          <div class="animate-pulse w-24">
            <div class="h-2 rounded bg-gray-400"></div>
          </div>
          <div class="animate-pulse w-24">
            <div class="grid grid-cols-3 gap-4">
              <div class="col-span-2 h-2 rounded bg-gray-400"></div>
              <div class="col-span-1 h-2 rounded bg-gray-400"></div>
            </div>
          </div>
        </div>
        <div v-else
          class="flex items-center justify-between">
          <p class="font-semibold lg:text-lg md:text-lg text-base">
            {{ labels.header }}
          </p>
          <vs-button
            @click="handleSideBar(true)()"
            :disabled="isAddBtnDisable"
            icon="add"
            type="border"
            class="lg:flex md:flex hidden"
          >
            Add {{ labels.sub }}
          </vs-button>
          <vs-button
            @click="handleSideBar(true)()"
            :disabled="isAddBtnDisable"
            type="border"
            class="lg:hidden md:hidden block"
          >
            Add
          </vs-button>
        </div>
      </div>
      <div v-if="!isFirstLoad"
        class="w-full">
        <div class="product-box w-full m-auto overflow-auto">
          <div v-if="tags.length">
            <div
              v-for="(item, index) in tags"
              :key="index"
              class="overflow-hidden inline-block select-none"
            >
              <p
                class="product-box py-2 px-4 rounded-lg ml-2 flex items-center"
              >
                {{ item.name }}
                <vs-icon
                  @click="handleRemove(item)"
                  icon="close"
                  size="12px"
                  class="ml-2 cursor-pointer"
                >
                </vs-icon>
              </p>
            </div>
          </div>
          <div
            v-else
            class="text-center lg:text-lg md:text-lg text-base select-none"
          >
            Add {{ labels.sub }} to get started
          </div>
        </div>
        <div v-if="tags.length" class="mt-2">
          <vs-button
            type="flat"
            color="danger"
            icon="close"
            @click="handleClearSelected"
            >Clear Selected {{ labels.header }}</vs-button
          >
        </div>
      </div>
      <div v-else
        class="animate-pulse">
        <div class="flex-1 space-y-6 py-1">
          <div class="h-2 rounded bg-gray-400"></div>
          <div class="space-y-3">
            <div class="grid grid-cols-3 gap-4">
              <div class="col-span-2 h-2 rounded bg-gray-400"></div>
              <div class="col-span-1 h-2 rounded bg-gray-400"></div>
            </div>
            <div class="h-2 rounded bg-gray-400"></div>
          </div>
        </div>
      </div>
    </vs-card>

    <vs-sidebar
      class="sidebarx sidebar-list relative overflow-auto"
      parent="body"
      position-right
      click-not-close
      v-model="isOpen"
    >
      <!-- HEADER -->
      <div class="header space-y-3">
        <p class="text-xl font-semibold px-4 pt-4">{{ labels.header }}</p>
        <vs-divider></vs-divider>
        <vs-input
          v-model="searchString"
          @input="handleSearch"
          :disabled="isSearching"
          :placeholder="searchPlaceholder"
          class="w-full pb-4 px-4"
        />
      </div>
      <!-- BODY -->
      <div class="scrollable-content">
        <div v-if="isSearching">Loading...</div>
        <div v-for="item in searchtState" :key="item._id">
          <vs-checkbox
            class="custom-checkbox"
            v-model="selected"
            :vs-value="item._id"
            @input="handleInputChange"
          >
            <span>
              {{ item.name }}
            </span>
          </vs-checkbox>
        </div>
      </div>
      <!-- FOOTER -->
      <div class="footer flex flex-col gap-3" slot="footer">
        <vs-button
          @click="handleSave"
          :disabled="isSaving || isSearching">
          <vs-icon
            v-if="isSaving"
            icon="refresh"
            class="animate-spin"
          ></vs-icon>
          Save
        </vs-button>
        <vs-button type="border" @click.prevent="handleSideBar(false)()">
          Exit
        </vs-button>
      </div>
    </vs-sidebar>
  </div>
</template>

<script>
import _ from 'lodash';
import { uniqBy } from 'lodash';

export default {
  name: 'Sidebar-List',
  props: {
    isAddBtnDisable: {
      type: Boolean,
      required: false,
      default: () => false
    },
    labels: {
      type: Object,
      required: false,
      default: () => ({
        header: 'Product',
        sub: 'product'
      })
    },
    selectedState: {
      type: Array,
      required: true,
      default: () => []
    },
    searchtState: {
      type: Array,
      required: true,
      default: () => []
    },
    isSearching: {
      type: Boolean,
      required: true,
      default: () => false
    },
    isFirstLoad: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      searchString: "",
      isOpen: false,
      isSaving: false,
      selected: [],
      clonedSelected: [],
      tags: []
    }
  },
  methods: {
    handleSideBar(isOpen) {
      return () => {
        this.isOpen = isOpen
      }
    },
    handleInputChange(selected) {
      let matchSelected = []
      let clonedSelected = this.clonedSelected
      selected.map(id => {
        const matched = this.searchtState.find(({ _id }) => _id === id)

        if (matched) matchSelected.push(matched)
      })

      clonedSelected = clonedSelected.filter(i => {
        const currentSearchMatched = this.searchtState.find(({ _id }) => _id === i._id)
        const currentSelectedMatched = selected.find(id => id === i._id)

        return !currentSearchMatched && currentSelectedMatched
      })

      this.clonedSelected = uniqBy([
        ...clonedSelected,
        ...matchSelected
      ], '_id')
    },
    handleSave(isClick = true) {
      this.isSaving = true;

      if (this.selected.length) {
        this.tags = this.clonedSelected
      } else {
        this.tags = [];
      }

      this.isOpen = false;
      this.isSaving = false;

      if (isClick) this.$emit('handleSave', this.clonedSelected)
    },
    handleClearSelected() {
      this.tags = [];
      this.selected = [];
      this.clonedSelected = [];

      this.$emit('handleSave', [])
    },
    handleSearch: _.debounce(function (searchString) {
      this.$emit('handleSearch', searchString)
    }, 1000),
    handleRemove(item) {
      const tags = this.tags.filter(
        (i) => i._id !== item._id
      );
      this.selected = this.selected.filter(
        (i) => i !== item._id
      );
      this.clonedSelected = this.clonedSelected.filter(
        (i) => i._id !== item._id
      )
      this.tags = tags

      this.$emit('handleSave', tags)
    },
  },
  computed: {
    searchPlaceholder() {
      return `Search ${this.labels.sub}`
    }
  },
  watch: {
    /**
     * Set selected state
     * @param selected []
     */
    'selectedState': function(selected) {
      if (
        selected.length &&
        !this.isAddBtnDisable
      ) {
        this.selected = selected.map(({ _id }) => (_id))
        this.tags = selected
        this.clonedSelected = selected
      }
    },
  }
}
</script>

<style lang="scss">
.sidebar-cont {
  .product-box {
    border: 1px solid #e0e0e0;
    padding: 15px 10px;
    border-radius: 0.5rem;

    p {
      color: #626262;
      background-color: #f0f0f0;
    }
  }
}

.vs-sidebar--background {
  z-index: 52000 !important;
}

.vs-sidebar-position-right {
  z-index: 53000 !important;
}

.sidebar-list .vs-sidebar-primary {
  display: flex;
  flex-direction: column;
  max-width: none;
}

.sidebar-list .custom-checkbox .vs-checkbox {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.sidebar-list .vs-sidebar--items {
  overflow: hidden !important;
}

.sidebar-list .vs-sidebar--items .scrollable-content {
  overflow-y: auto;
  padding-top: 14px;
  padding-bottom: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 8px;
  height: calc(100% - 104px);
}

.sidebar-list .vs-sidebar--items .header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.sidebar-list .vs-sidebar--footer .footer {
  background-color: white;
  padding: 16px;
  z-index: 10;
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.1);
}

.sidebarx {
  .vs-sidebar {
    max-width: 350px;

    &--items {
      overflow-y: auto;
      overflow-x: hidden !important;
    }
  }
}
</style>