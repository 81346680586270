<template>
  <div>
    <vs-collapse
      type="border">
      <vs-collapse-item>
        <div slot="header">
          <h6 class="font-bold">Discount Group <span class="text-red-600 text-xs">* Click to manage groups discount.</span></h6>
        </div>
        <div class="w-full flex justify-end">
          <vs-button
            align="right"
            icon-pack="feather"
            icon="icon-edit"
            @click="() => createUpdateModal = true">Create</vs-button>
        </div>
        <vs-table
          ref="table"
          :sst="true"
          :total="totalDocs"
          :data="groups"
          @search="handleSearch"
          @change-page="handleChangePage"
          :max-items="dataTableParams.limit"
          search
          :noDataText="noDataText"
        >
          <div slot="header" class="flex">
            <vs-select
              placeholder="10"
              vs-multiple
              autocomplete
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item,index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>

          <template slot="thead">
            <vs-th>Group Name</vs-th>
            <vs-th>Actions</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].group_name">
                {{
                data[indextr].group_name
                }}
              </vs-td>
              <vs-td :data="data[indextr]._id">
                <div class="flex items-center justify-start space-x-2">
                  <vx-tooltip text="Update" position="left" >
                    <vs-button
                      type="border"
                      size="small"
                      @click="openPopup(data[indextr]._id, 'edit')"
                      icon-pack="feather"
                      icon="icon-edit"
                    ></vs-button>
                  </vx-tooltip>
                  <vx-tooltip text="Delete" position="left">
                    <vs-button
                      type="border"
                      size="small"
                      color="danger"
                      @click="openPopup(data[indextr]._id, 'delete')"
                      icon-pack="feather"
                      icon="icon-trash"
                    ></vs-button>
                  </vx-tooltip>
                </div>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>

        <div class="flex items-center justify-between space-x-4 mt-3">
          <p class="text-sm font-medium">
            {{
            dataTableParams.page * dataTableParams.limit -
            (dataTableParams.limit - 1)
            }}
            -
            {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
            ? dataTableParams.page * dataTableParams.limit
            : totalDocs
            }}
            of {{ totalDocs }}
          </p>
          <div v-if="serverResponded">
            <paginate
              :page-count="totalPage"
              :click-handler="handleChangePage"
              class="pagination"
              :page-range="9"
              :prevText="'<'"
              :nextText="'>'"
            ></paginate>
          </div>
        </div>
      </vs-collapse-item>
    </vs-collapse>
    <vs-popup
      :active.sync="createUpdateModal"
      class="group-create-update-modal">
      <div class="p-6">
        <vs-card class="mb-0">
          <div class="w-full" slot="header">
            <h6 class="font-bold">Group details</h6>
          </div>
          <vs-form autocomplete="off">
            <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
              <!-- Group Name -->
              <vs-col vs-lg="12" vs-sm="12" vs-xs="12">
                <vs-input
                  :success="!errors.first('group.group_name') && details.group_name != ''"
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="!!errors.first('group.group_name')"
                  v-validate="'required'"
                  name="group_name"
                  data-vv-as="Group Name"
                  label="Group Name"
                  class="custom-input w-full"
                  data-vv-scope="group"
                  :danger-text="errors.first('group.group_name') ? 'Group name is required' : ''"
                  v-model="details.group_name" />
              </vs-col>
            </vs-row>

            <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="space-x-4">
              <vs-button
                size="lg"
                color="primary"
                class="flex-1"
                @click="handleSave"
              >Save</vs-button>
              <vs-button class="flex-1" size="lg" color="danger" @click="createUpdateModal = false">Cancel</vs-button>
            </vs-row>
          </vs-form>
        </vs-card>
      </div>
    </vs-popup>
    <vs-popup :active.sync="deleteModal" title="Delete Rule">
      <div class="p-6">
        <p class="text-center text-black text-lg font-medium mb-6">Are you sure you want to delete this group?</p>
        <div class="flex items-center justify-between space-x-4">
          <vs-button
            size="lg"
            color="primary"
            class="flex-1"
            @click="handleDelete"
          >Yes</vs-button>
          <vs-button class="flex-1" size="lg" color="danger" @click="deleteModal = false">Cancel</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>

</template>

<script>
import { debounce, cloneDeep } from 'lodash';
import { mapActions } from 'vuex';

export default {
  name: 'CRUDGroup',
  data() {
    return {
      createUpdateModal: false,
      deleteModal: false,
      groups: [],
      serverResponded: false,
      dataTableParams: {
        search: "",
        sort: "_id",
        dir: "desc",
        page: 1,
        limit: 10
      },
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 }
      ],
      totalDocs: 0,
      noDataText: 'No discount groups available.',
      details: {
        group_name: ""
      },
      selected: ""
    }
  },
  methods: {
    ...mapActions('discount', [
      "getBulkDiscountGroups",
      "updateBulkDiscountGroup",
      "createBulkDiscountGroup",
      "deleteBulkDiscountGroup"
    ]),
    async fetchGroups() {
      this.groups = []
      this.noDataText = "Loading...";

      try {
        const res = await this.getBulkDiscountGroups(this.dataTableParams)

        this.groups = res.data.data.docs
        this.totalDocs = res.data.data.pagination.total || 0;
        this.dataTableParams.page = res.data.data.pagination.page;
        this.serverResponded = true;
      } catch (err) {
        console.log('Error fetching groups:: ', err.message)
      }
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.fetchGroups();
    },
    handleSearch: debounce(function (searching) {
      this.resetTable(searching)
    }, 1000),
    async handleDelete() {
      this.$vs.loading();

      try {
        await this.deleteBulkDiscountGroup(this.selected)

        this.$vs.notify({
          title: "Success",
          text: "Discount group deleted successfully",
          color: "success",
        });

        this.resetTable()

        this.deleteModal = false

        this.$emit('fetch')
      } catch (err) {
        this.$vs.notify({
          title: "Failed",
          text: "Failed to delete discount group.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      } finally {
        this.$vs.loading.close();
      }
    },
    async handleSave() {
      const isValid = await this.$validator.validate('group.*');

      if (!isValid) return

      if (this.selected) return this.updateGroup()
      return this.createGroup()
    },
    openPopup(id, mode) {
      if (mode === 'edit') {
        this.createUpdateModal = true
      } else {
        this.deleteModal = true
      }

      this.selected = id;
      this.details = cloneDeep(this.groups.find((group) =>  group._id === id))
    },
    async createGroup() {
      this.$vs.loading();

      try {
        await this.createBulkDiscountGroup(this.details)

        this.$vs.notify({
          title: "Success",
          text: "Discount group created successfully",
          color: "success",
        });

        this.resetTable()

        this.createUpdateModal = false

        this.$emit('fetch')
      } catch (err) {
        this.$vs.notify({
          title: "Failed",
          text: "Failed to create discount group.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      } finally {
        this.$vs.loading.close();
      }
    },
    async updateGroup() {
      this.$vs.loading();

      try {
        await this.updateBulkDiscountGroup({
          id: this.selected,
          body: {
            group_name: this.details.group_name
          }
        })

        this.$vs.notify({
          title: "Success",
          text: "Discount group updated successfully",
          color: "success",
        });

        this.resetTable()

        this.createUpdateModal = false

        this.$emit('fetch')
      } catch (err) {
        this.$vs.notify({
          title: "Failed",
          text: "Failed to update discount group.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      } finally {
        this.$vs.loading.close();
      }
    },
    clearFields() {
      this.details = {
        group_name: ""
      }
      this.selected = ""
    },
    resetTable(searching = '') {
      this.serverResponded = false;
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;

      this.fetchGroups();
    }
  },
  computed: {
    totalPage: function() {
      return this.totalDocs / this.dataTableParams.limit >
      parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    }
  },
  watch: {
    "dataTableParams.limit": function(newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.fetchGroups();
      }
    },
    createUpdateModal: function(isOpen) {
      if (!isOpen) this.clearFields()
      if (isOpen) {
        this.$nextTick(() => {
          this.errors.clear()
        })
      }
    },
    deleteModal: function(isOpen) {
      if (!isOpen)  this.clearFields()
    },
  },
  created() {
    this.fetchGroups()
  }
}
</script>