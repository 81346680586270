<template>
  <vs-form autocomplete="off">
    <div class="py-4">
      <h6 class="font-bold">Discount details</h6>
    </div>

    <!-- Discount Name -->
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-lg="4" vs-sm="8" vs-xs="12" class="mb-5" v-if="isFirstLoad">
        <label class="custom-label">Name</label>
        <div class="p-4 pl-0">
          <div class="animate-pulse w-full">
            <div class="grid grid-cols-4 gap-4">
              <div class="col-span-2 h-2 rounded bg-gray-400"></div>
              <div class="col-span-1 h-2 rounded bg-gray-400"></div>
              <div class="col-span-1 h-2 rounded bg-gray-400"></div>
            </div>
          </div>
        </div>
      </vs-col>
      <vs-col vs-lg="4" vs-sm="8" vs-xs="12" v-else>
        <vs-input
          :success="!errors.first('basic.discount_name') && details.discount_name != ''"
          val-icon-success="done"
          val-icon-danger="error"
          :danger="!!errors.first('basic.discount_name')"
          v-validate="'required'"
          name="discount_name"
          data-vv-as="name"
          label="Name"
          class="custom-input w-full"
          data-vv-scope="basic"
          :danger-text="errors.first('basic.discount_name') ? 'Discount name is required' : ''"
          v-model="details.discount_name" />
      </vs-col>
    </vs-row>

    <!-- Validity From & To -->
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-lg="4" vs-sm="8" vs-xs="12" class="mb-5" v-if="isFirstLoad">
        <label class="custom-label">Valid From - To</label>
        <div class="p-4 pl-0">
          <div class="animate-pulse w-full">
            <div class="grid grid-cols-4 gap-4">
              <div class="col-span-2 h-2 rounded bg-gray-400"></div>
              <div class="col-span-1 h-2 rounded bg-gray-400"></div>
              <div class="col-span-1 h-2 rounded bg-gray-400"></div>
            </div>
          </div>
        </div>
      </vs-col>
      <vs-col vs-lg="4" vs-sm="8" vs-xs="12" v-else>
        <div class="vs-con-input-label custom-input w-full">
          <label class="custom-label">Valid From - To</label>
          <custom-date-range-picker
            :date-range="validityRange"
            :min-date="minDate"
            :custom-ranges="customRanges"
            @update="handleDateRangePicker">
          </custom-date-range-picker>
        </div>
      </vs-col>
    </vs-row>

    <!-- Is Tier Costing -->
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-lg="4" vs-sm="8" vs-xs="12" class="custom-input">
        <h6 class="custom-label mb-2">Is tier cost?</h6>
        <p class="mb-4 text-gray-500">Discount/Tier Cost. Will remove all existing rules.</p>
        <div class="p-4 pl-0" v-if="isFirstLoad">
          <div class="animate-pulse w-16">
            <div class="h-2 rounded bg-gray-400"></div>
          </div>
        </div>
        <vs-switch v-model="details.isTierCosting" @input="switchHandler" v-else/>
      </vs-col>
    </vs-row>

    <!-- Products -->
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
        <h6 class="font-bold mb-2">Products</h6>
        <p class="mb-4 text-gray-500">If switched off, this filter would apply to all products.</p>
        <div class="p-4 pl-0" v-if="isFirstLoad">
          <div class="animate-pulse w-16">
            <div class="h-2 rounded bg-gray-400"></div>
          </div>
        </div>
        <vs-switch v-model="details.product_is_equal" v-else/>
      </vs-col>
    </vs-row>

    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-lg="6" vs-sm="10" vs-xs="12">
        <sidebar
          class="mt-3"
          :isFirstLoad="isFirstLoad"
          :isAddBtnDisable="!details.product_is_equal"
          :searchtState="products"
          :selectedState="selectedProducts"
          :isSearching="sidebarSearching"
          @handleSearch="getProducts"
          @handleSave="(products) => selectedProducts = products"
        ></sidebar>
      </vs-col>
    </vs-row>

    <!-- Clinics -->
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-lg="4" vs-sm="8" vs-xs="12">
        <h6 class="font-bold mb-2">Clinics</h6>
        <p class="mb-4 text-gray-500">If switched off, this filter would apply to all clinics.</p>
        <div class="p-4 pl-0" v-if="isFirstLoad">
          <div class="animate-pulse w-16">
            <div class="h-2 rounded bg-gray-400"></div>
          </div>
        </div>
        <vs-switch v-model="details.clinic_is_equal" v-else/>
      </vs-col>
    </vs-row>

    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-lg="6" vs-sm="10" vs-xs="12">
        <sidebar
          class="mt-3"
          :isFirstLoad="isFirstLoad"
          :isAddBtnDisable="!details.clinic_is_equal"
          :labels="clinicLabels"
          :searchtState="clinics"
          :selectedState="selectedClinics"
          :isSearching="sidebarSearching"
          @handleSearch="getClinics"
          @handleSave="(clinics) => selectedClinics = clinics"
        ></sidebar>
      </vs-col>
    </vs-row>

    <!-- Group -->
    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" v-if="!details.isTierCosting">
      <vs-col vs-lg="4" vs-sm="8" vs-xs="12" class="mb-5" v-if="isFirstLoad">
        <label class="custom-label">Group</label>
        <div class="p-4 pl-0">
          <div class="animate-pulse w-full">
            <div class="grid grid-cols-4 gap-4">
              <div class="col-span-2 h-2 rounded bg-gray-400"></div>
              <div class="col-span-1 h-2 rounded bg-gray-400"></div>
              <div class="col-span-1 h-2 rounded bg-gray-400"></div>
            </div>
          </div>
        </div>
      </vs-col>
      <div class="w-full" v-else>
        <vs-col vs-lg="4" vs-sm="8" vs-xs="11" class="mb-5">
          <label class="custom-label">Group</label>
          <vs-select
            v-model="details.groupId"
            class="w-full"
            autocomplete
            name="group">
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.label"
              v-for="(item, index) in groups" />
          </vs-select>
        </vs-col>
        <vs-col class="ml-1" vs-lg="4" vs-sm="4" vs-xs="1" v-if="details.groupId">
          <label class="custom-label">&nbsp;</label>
          <vs-button color="danger" type="flat" @click="removeGroup">
            <vs-icon icon-pack="feather" icon="icon-trash-2" size="20px" class="w-full h-full"></vs-icon>
          </vs-button>
        </vs-col>
      </div>
    </vs-row>

    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" v-if="!details.isTierCosting">
      <vs-col vs-lg="12" vs-sm="12" vs-xs="12">
        <groups @fetch="getGroups"></groups>
      </vs-col>
    </vs-row>

    <!-- Rules List -->
    <vs-divider></vs-divider>
    <rules
      class="mt-4"
      :isFirstLoad="isFirstLoad"
      :isTierCosting="details.isTierCosting"
      :rules="details.discount_rules"></rules>
  </vs-form>
</template>

<script>
import moment from 'moment';
import CustomDateRangePicker from "@/views/components/insights/common/GlobalFilters/CustomDateRangePicker.vue";
import Sidebar from "@/views/components/WebStore/discount/Sidebar.vue"
import Groups from "@/views/components/WebStore/discount/Groups.vue"
import Rules from "@/views/components/WebStore/discount/Rules.vue"
import _ from 'lodash';
import { mapActions } from 'vuex';

export default {
  components: {
    CustomDateRangePicker,
    Sidebar,
    Groups,
    Rules
  },
  props: {
    details: {
      type: Object,
      required: true,
      default: () => ({
        discount_name: "",
        clinic_is_equal: false,
        product_is_equal: false,
        rule_valid_from: "",
        rule_valid_to: "",
        isTierCosting: false,
        discount_rules: [],
        products: [],
        clinics: [],
        group: null,
        groupId: null
      })
    },
    saving: {
      type: Boolean,
      required: true,
      default: () => false
    }
  },
  data() {
    return {
      clinics: [],
      selectedClinics: [],
      products: [],
      selectedProducts: [],
      groups: [],
      isFirstLoad: true,
      sidebarSearching: false
    }
  },
  methods: {
    ...mapActions("storeProducts", [
      "fetchProducts"
    ]),
    ...mapActions("clinic", [
      "fetchClinics"
    ]),
    ...mapActions('discount', [
      "getBulkDiscountGroups",
    ]),
    switchHandler() {
      this.details.discount_rules.splice(0, this.details.discount_rules.length)
    },
    async fetchInit() {
      try {
        await this.getProducts()
        await this.getClinics()
        await this.getGroups()

        this.isFirstLoad = false;
      } catch (err) {
        console.log('Error fetching init data:: ', err.message)
      }
    },
    async getProducts(searchString = '') {
      this.products = []
      this.sidebarSearching = true

      try {
        const res = await this.fetchProducts({
          limit: 100,
          search: searchString,
          excludeType: 'bundle'
        })

        const formattedProducts = res.data.data.docs.map(({ _id, name }) => ({ _id, name }))

        this.products = formattedProducts
      } catch (err) {
        console.log('Error fetching products:: ', err.message)
        return {
          isError: true,
          message: err.message
        }
      } finally {
        this.sidebarSearching = false
      }
    },
    async getClinics(searchString = '') {
      this.clinics = []
      this.sidebarSearching = true

      try {
        const res = await this.fetchClinics({
          limit: 100,
          search: searchString,
          userType: 'clinic',
          page: 1,
          sort: 'createdAt',
          dir: 'desc',
          activeClinicId: ''
        })

        const formattedClinics = res.data.data.docs.map(({ _id, clinicName: name }) => ({ _id, name }))

        this.clinics = formattedClinics
      } catch (err) {
        console.log('Error fetching clinics:: ', err.message)
        return {
          isError: true,
          message: err.message
        }
      } finally {
        this.sidebarSearching = false
      }
    },
    async getGroups() {
      try {
        const res = await this.getBulkDiscountGroups({
          limit: 500,
          sort: '_id',
          dir: 'desc'
        })

        this.groups = res.data.data.docs.map(({ group_name: label, _id: value }) => ({ value, label }))
      } catch (err) {
        console.log('Error fetching groups:: ', err.message)
        return {
          isError: true,
          message: err.message
        }
      }
    },
    async saveData() {
      const isValid = await this.$validator.validate('basic.*');
      const formattedRules = this.details.discount_rules.map((rule) => {
        if (rule._id) delete rule._id
        return rule
      })

      if (isValid) {
        const payload = {
          ...this.details,
          group: this.details.groupId,
          products: this.selectedProducts.map(({ _id }) => (_id)),
          clinics: this.selectedClinics.map(({ _id }) => (_id)),
          rules: formattedRules
        }

        if (!payload.group) delete payload.group

        delete payload.discount_rules
        delete payload.groupId
        delete payload._id

        this.$emit('handleSave', payload)
      }
    },
    handleDateRangePicker(value) {
      this.details.rule_valid_from = moment(value.startDate).format('YYYY-MM-DD')
      this.details.rule_valid_to = moment(value.endDate).format('YYYY-MM-DD')
    },
    removeGroup() {
      this.details.groupId = ""
    }
  },
  computed: {
    validityRange() {
      let startDate = moment().format('YYYY-MM-DD')
      let endDate = moment().add(7, 'days').format('YYYY-MM-DD')

      if (this.details.rule_valid_from) startDate = moment(this.details.rule_valid_from).format('YYYY-MM-DD')
      if (this.details.rule_valid_to) endDate = moment(this.details.rule_valid_to).format('YYYY-MM-DD')

      return {
        startDate,
        endDate
      }
    },
    minDate() {
      return moment().subtract(1, 'days')._d
    },
    customRanges() {
      const thisWeekStart = moment().startOf("week")._d;
      const thisWeekEnd = moment().endOf("week")._d;
      const thisMonthStart = moment().startOf("month")._d;
      const thisMonthEnd = moment().endOf("month")._d;
      const thisYearStart = moment().startOf("year")._d;
      const thisYearEnd = moment().endOf("year")._d;

      return {
        "This week": [thisWeekStart, thisWeekEnd],
        "This month": [thisMonthStart, thisMonthEnd],
        "This year": [thisYearStart, thisYearEnd],
      };
    },
    clinicLabels() {
      return {
        header: 'Clinic',
        sub: 'clinic'
      }
    }
  },
  watch: {
    'saving': function (save) {
      if (save) this.saveData()
    },
    'details.products': function(products) {
      this.selectedProducts = products
    },
    'details.clinics': function(clinics) {
      this.selectedClinics = clinics
    },
    'details.clinic_is_equal': function(toggle) {
      if (!toggle) this.details.clinics.splice(0, this.details.clinics.length)
    },
    'details.product_is_equal': function(toggle) {
      if (!toggle) this.details.products.splice(0, this.details.products.length)
    },
  },
  created() {
    this.fetchInit()
  }
}
</script>

<style lang="scss">
.custom-input {
  margin-bottom: 20px;

  .vue-daterange-picker > .form-control {
    padding: .7rem !important;
  }
}

.custom-input label,
.custom-label {
  display: block;
  padding: 5px 0;
  font-size: 12px;
}

.tooltip-cont {
  width: fit-content;
}
</style>