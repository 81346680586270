<template>
  <div>
    <div class="w-full flex justify-end mb-4">
      <div class="animate-pulse w-24" v-if="isFirstLoad">
        <div class="grid grid-cols-3 gap-4">
          <div class="col-span-2 h-2 rounded bg-gray-400"></div>
          <div class="col-span-1 h-2 rounded bg-gray-400"></div>
        </div>
      </div>
      <vs-button
        v-else
        align="right"
        icon-pack="feather"
        icon="icon-edit"
        @click="() => createUpdateModal = true">Create</vs-button>
    </div>

    <div
      v-if="isFirstLoad"
      class="animate-pulse">
      <div class="flex-1 space-y-6 py-1">
        <div class="h-2 rounded bg-gray-400"></div>
        <div class="space-y-3">
          <div class="grid grid-cols-3 gap-4">
            <div class="col-span-2 h-2 rounded bg-gray-400"></div>
            <div class="col-span-1 h-2 rounded bg-gray-400"></div>
          </div>
          <div class="h-2 rounded bg-gray-400"></div>
        </div>
      </div>
    </div>
    <vs-table
      v-else
      ref="table"
      :sst="true"
      :data="rules"
      :noDataText="noDataText"
      >
      <template slot="thead">
        <vs-th v-if="!isTierCosting">Discount Title</vs-th>
        <vs-th v-if="!isTierCosting">Discount Type</vs-th>
        <vs-th v-if="!isTierCosting">Discount Value</vs-th>
        <vs-th v-if="!isTierCosting">Discount Price</vs-th>
        <vs-th>Min. Qty</vs-th>
        <vs-th>Max. Qty</vs-th>
        <vs-th v-if="!isTierCosting">Display Message Min.</vs-th>
        <vs-th v-if="!isTierCosting">Display Message Max.</vs-th>
        <vs-th v-if="!isTierCosting">Min. Purchase Amount</vs-th>
        <vs-th v-if="isTierCosting">Fresh Price</vs-th>
        <vs-th v-if="isTierCosting">Cost Price</vs-th>
        <vs-th>Actions</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].discount_title" v-if="!isTierCosting">
            {{
            data[indextr].discount_title
            }}
          </vs-td>
          <vs-td :data="data[indextr].discount_type" v-if="!isTierCosting">
            {{
            data[indextr].discount_type
            }}
          </vs-td>
          <vs-td :data="data[indextr].discount_value" v-if="!isTierCosting">
            {{
            data[indextr].discount_value
            }}
          </vs-td>
          <vs-td :data="data[indextr].discount_price" v-if="!isTierCosting">
            {{
            data[indextr].discount_price
            }}
          </vs-td>
          <vs-td :data="data[indextr].min_qty">
            {{
            data[indextr].min_qty
            }}
          </vs-td>
          <vs-td :data="data[indextr].max_qty">
            {{
            data[indextr].max_qty
            }}
          </vs-td>
          <vs-td :data="data[indextr].display_message_min" v-if="!isTierCosting">
            {{
            data[indextr].display_message_min
            }}
          </vs-td>
          <vs-td :data="data[indextr].display_message_max" v-if="!isTierCosting">
            {{
            data[indextr].display_message_max
            }}
          </vs-td>
          <vs-td :data="data[indextr].minimum_purchase_amount" v-if="!isTierCosting">
            {{
            data[indextr].minimum_purchase_amount
            }}
          </vs-td>
          <vs-td :data="data[indextr].fresh_price" v-if="isTierCosting">
            {{
            data[indextr].fresh_price
            }}
          </vs-td>
          <vs-td :data="data[indextr].cost_price" v-if="isTierCosting">
            {{
            data[indextr].cost_price
            }}
          </vs-td>
          <vs-td :data="data[indextr]._id">
            <div class="flex items-center justify-start space-x-2">
              <vx-tooltip text="Update" position="left" >
                <vs-button
                  type="border"
                  size="small"
                  @click="openPopupHandler(indextr, 'edit')"
                  icon-pack="feather"
                  icon="icon-edit"
                ></vs-button>
              </vx-tooltip>
              <vx-tooltip text="Delete" position="left">
                <vs-button
                  type="border"
                  size="small"
                  color="danger"
                  @click="openPopupHandler(indextr, 'delete')"
                  icon-pack="feather"
                  icon="icon-trash"
                ></vs-button>
              </vx-tooltip>
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-popup
      :active.sync="createUpdateModal"
      class="rule-create-update-modal">
      <div class="p-6">
        <vs-card class="mb-0">
          <div class="w-full" slot="header">
            <h6 class="font-bold">Rule details</h6>
          </div>
          <form
            data-vv-scope="rule"
            :class="{'hidden': isTierCosting}">
            <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
              <!-- Discount Title -->
              <vs-col vs-lg="5" vs-sm="5" vs-xs="12" class="sm:mr-2">
                <vs-input
                  :success="!errors.first('rule.discount_title') && details.discount_title != ''"
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="!!errors.first('rule.discount_title')"
                  v-validate="'required'"
                  name="discount_title"
                  data-vv-name="discount_title"
                  label="Discount Title"
                  class="custom-input w-full"
                  :danger-text="errors.first('rule.discount_title') ? 'Discount title is required': ''"
                  v-model="details.discount_title" />
              </vs-col>

              <!-- Discount Price -->
              <vs-col vs-lg="5" vs-sm="5" vs-xs="12" class="custom-input">
                <label class="custom-label">Discount Price</label>
                <vs-select
                  v-model="details.discount_price"
                  class="w-full"
                  autocomplete
                  name="discount_price"
                  val-icon-success="done"
                  :danger="!!errors.first('rule.discount_price')"
                  :danger-text="errors.first('rule.discount_price') ? 'Discount Price is required' : ''"
                  :success="!errors.first('rule.discount_price') && details.discount_price != ''"
                  val-icon-danger="error"
                  v-validate="'required'">
                  <vs-select-item :key="index" :value="item.value" :text="item.label"
                    v-for="(item, index) in discountPriceEnum" />
                </vs-select>
              </vs-col>
            </vs-row>

            <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
              <!-- Minimum Qty -->
              <vs-col vs-lg="5" vs-sm="5" vs-xs="12" class="sm:mr-2">
                <vs-input
                  type="number"
                  :success="!errors.first('rule.min_qty')"
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="!!errors.first('rule.min_qty')"
                  v-validate="'required'"
                  name="min_qty"
                  data-vv-as="Minimum Qty"
                  label="Minimum Qty"
                  class="custom-input w-full"
                  :danger-text="errors.first('rule.min_qty') ? 'Min. qty is required' : ''"
                  v-model="details.min_qty" />
              </vs-col>

              <!-- Maximum Qty -->
              <vs-col vs-lg="5" vs-sm="5" vs-xs="12">
                <vs-input
                  type="number"
                  :success="!errors.first('rule.max_qty')"
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="!!errors.first('rule.max_qty')"
                  v-validate="'required'"
                  name="max_qty"
                  data-vv-as="Maximum Qty"
                  label="Maximum Qty"
                  class="custom-input w-full"
                  :danger-text="errors.first('rule.max_qty') ? 'Max. qty is required' : ''"
                  v-model="details.max_qty" />
              </vs-col>
            </vs-row>

            <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
              <!-- Minimum Purchase Amnt -->
              <vs-col vs-lg="5" vs-sm="5" vs-xs="12" class="sm:mr-2">
                <vs-input
                  type="number"
                  :success="!errors.first('rule.minimum_purchase_amount')"
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="!!errors.first('rule.minimum_purchase_amount')"
                  v-validate="'required'"
                  name="minimum_purchase_amount"
                  data-vv-as="Minimum Purchase Amount"
                  label="Minimum Purchase Amount"
                  class="custom-input w-full"
                  :danger-text="errors.first('rule.minimum_purchase_amount') ? 'Min. purchase amnt is required': ''"
                  v-model="details.minimum_purchase_amount" />
              </vs-col>

              <!-- Display Message Min. -->
              <vs-col vs-lg="5" vs-sm="5" vs-xs="12">
                <vs-input
                  :success="!errors.first('rule.display_message_min') && details.display_message_min != ''"
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="!!errors.first('rule.display_message_min')"
                  v-validate="'required'"
                  name="display_message_min"
                  data-vv-as="Display Message Min."
                  label="Display Message Min."
                  class="custom-input w-full"
                  :danger-text="errors.first('rule.display_message_min') ? 'Display message min. is required': ''"
                  v-model="details.display_message_min" />
              </vs-col>
            </vs-row>

            <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
              <!-- Display Message Max. -->
              <vs-col vs-lg="5" vs-sm="5" vs-xs="12" class="sm:mr-2">
                <vs-input
                  :success="!errors.first('rule.display_message_max') && details.display_message_max != ''"
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="!!errors.first('rule.display_message_max')"
                  v-validate="'required'"
                  name="display_message_max"
                  data-vv-as="Display Message Max."
                  label="Display Message Max."
                  class="custom-input w-full"
                  :danger-text="errors.first('rule.display_message_max') ? 'Display message max. is required' : ''"
                  v-model="details.display_message_max" />
              </vs-col>

              <!-- Discount Type -->
              <vs-col vs-lg="5" vs-sm="5" vs-xs="12" class="custom-input">
                <label class="custom-label">Discount Type</label>
                <vs-select
                  v-model="details.discount_type"
                  class="w-full"
                  autocomplete
                  name="discount_type"
                  val-icon-success="done"
                  :danger="!!errors.first('rule.discount_type')"
                  :danger-text="errors.first('rule.discount_type') ? 'Discount Type is required' : ''"
                  :success="!errors.first('rule.discount_type') && details.discount_type != ''"
                  val-icon-danger="error"
                  v-validate="'required'">
                  <vs-select-item :key="index" :value="item.value" :text="item.label"
                    v-for="(item, index) in discountTypeEnum" />
                </vs-select>
              </vs-col>
            </vs-row>

            <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
              <!-- Discount Value -->
              <vs-col vs-lg="5" vs-sm="5" vs-xs="12" class="sm:mr-2">
                <vs-input
                  type="number"
                  :success="!errors.first('rule.discount_value')"
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="!!errors.first('rule.discount_value')"
                  v-validate="'required'"
                  name="discount_value"
                  data-vv-as="Discount Value"
                  label="Discount Value"
                  class="custom-input w-full"
                  :danger-text="errors.first('rule.discount_value') ? 'Discount value is required' : ''"
                  v-model="details.discount_value" />
              </vs-col>
            </vs-row>

            <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="space-x-4">
              <vs-button
                size="lg"
                color="primary"
                class="flex-1"
                @click="handleSave"
              >Save</vs-button>
              <vs-button class="flex-1" size="lg" color="danger" @click="createUpdateModal = false">Cancel</vs-button>
            </vs-row>
          </form>

          <form
            data-vv-scope="promo"
            :class="{'hidden': !isTierCosting}">
            <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
              <!-- Minimum Qty -->
              <vs-col vs-lg="5" vs-sm="5" vs-xs="12" class="sm:mr-2">
                <vs-input
                  type="number"
                  :success="!errors.first('promo.min_qty')"
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="!!errors.first('promo.min_qty')"
                  v-validate="'required'"
                  name="min_qty"
                  data-vv-as="Minimum Qty"
                  label="Minimum Qty"
                  class="custom-input w-full"
                  :danger-text="errors.first('promo.min_qty') ? 'Min. qty is required' : ''"
                  v-model="details.min_qty" />
              </vs-col>

              <!-- Maximum Qty -->
              <vs-col vs-lg="5" vs-sm="5" vs-xs="12">
                <vs-input
                  type="number"
                  :success="!errors.first('promo.max_qty')"
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="!!errors.first('promo.max_qty')"
                  v-validate="'required'"
                  name="max_qty"
                  data-vv-as="Maximum Qty"
                  label="Maximum Qty"
                  class="custom-input w-full"
                  :danger-text="errors.first('promo.max_qty') ? 'Max. qty is required' : ''"
                  v-model="details.max_qty" />
              </vs-col>
            </vs-row>

            <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
              <!-- Fresh Price -->
              <vs-col vs-lg="5" vs-sm="5" vs-xs="12" class="sm:mr-2">
                <vs-input
                  type="number"
                  :success="!errors.first('promo.fresh_price')"
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="!!errors.first('promo.fresh_price')"
                  v-validate="'required'"
                  name="fresh_price"
                  data-vv-name="fresh_price"
                  label="Fresh Price"
                  class="custom-input w-full"
                  :danger-text="errors.first('promo.fresh_price') ? 'Fresh Price is required' : ''"
                  v-model="details.fresh_price" />
              </vs-col>

              <!-- Cost Price -->
              <vs-col vs-lg="5" vs-sm="5" vs-xs="12">
                <vs-input
                  type="number"
                  :success="!errors.first('promo.cost_price')"
                  val-icon-success="done"
                  val-icon-danger="error"
                  :danger="!!errors.first('promo.cost_price')"
                  v-validate="'required'"
                  name="cost_price"
                  data-vv-name="cost_price"
                  label="Cost Price"
                  class="custom-input w-full"
                  :danger-text="errors.first('promo.cost_price') ? 'Cost Price is required' : ''"
                  v-model="details.cost_price" />
              </vs-col>
            </vs-row>

            <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="space-x-4">
              <vs-button
                size="lg"
                color="primary"
                class="flex-1"
                @click="handleSave"
              >Save</vs-button>
              <vs-button class="flex-1" size="lg" color="danger" @click="createUpdateModal = false">Cancel</vs-button>
            </vs-row>
          </form>
        </vs-card>
      </div>
    </vs-popup>
    <vs-popup :active.sync="deleteModal" title="Delete Rule">
      <div class="p-6">
        <p class="text-center text-black text-lg font-medium mb-6">Are you sure you want to delete this rule?</p>
        <div class="flex items-center justify-between space-x-4">
          <vs-button
            size="lg"
            color="primary"
            class="flex-1"
            @click="handleDelete"
          >Yes</vs-button>
          <vs-button class="flex-1" size="lg" color="danger" @click="deleteModal = false">Cancel</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  props: {
    rules: {
      type: Array,
      required: true,
      default: () => ([])
    },
    isFirstLoad: {
      type: Boolean,
      required: true,
      default: () => false
    },
    isTierCosting: {
      type: Boolean,
      required: true,
      default: () => false
    }
  },
  data() {
    return {
      noDataText: 'No rules available.',
      createUpdateModal: false,
      deleteModal: false,
      selectedId: "",
      details: {
        discount_title: '',
        discount_price: '',
        min_qty: 0,
        max_qty: 0,
        minimum_purchase_amount: 0,
        display_message_min: '',
        display_message_max: '',
        discount_type: 'percentage',
        discount_value: undefined,
        cost_price: undefined,
        fresh_price: undefined
      },
      discountPriceEnum: [
        { label: 'List Price', value: 'List Price' },
        { label: 'Nurse Price', value: 'Nurse Price' },
        { label: 'Doctor Price', value: 'Doctor Price' },
        { label: 'RRP', value: 'RRP' }
      ],
      discountTypeEnum: [
        { label: 'percentage', value: 'percentage' },
        { label: 'fix', value: 'fix' }
      ]
    }
  },
  methods: {
    async handleSave() {
      const scope = this.isTierCosting ? 'promo.*' : 'rule.*'
      const isValid = await this.$validator.validate(scope);

      if (!isValid) return

      if (typeof this.selectedId === 'number') return this.updateRule()

      return this.createRule()
    },
    createRule() {
      try {
        const extraDetails = this.isTierCosting ?
          { discount_value: 0, discount_price: 'Promo', discount_type: 'fix', discount_title: 'n/a', display_message_min: 'n/a', display_message_max: 'n/a' }
        : {};

        this.rules.push({
          ...this.details,
          ...extraDetails
        })

        setTimeout(() => {
          this.$vs.notify({
            title: "Success",
            text: "Discount rule created successfully",
            color: "success",
          });

          this.createUpdateModal = false
        }, 200)
      } catch (err) {
        this.$vs.notify({
          title: "Failed",
          text: "Failed to create discount rule.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      }
    },
    updateRule() {
      try {
        this.rules[this.selectedId] = this.details

        setTimeout(() => {
          this.$vs.notify({
            title: "Success",
            text: "Discount rule updated successfully",
            color: "success",
          });

          this.createUpdateModal = false
        }, 200)
      } catch (err) {
        this.$vs.notify({
          title: "Failed",
          text: "Failed to update discount rule.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      }
    },
    handleDelete() {
      try {
        this.rules.splice(this.selectedId, 1)

        setTimeout(() => {
          this.$vs.notify({
            title: "Success",
            text: "Discount rule deleted successfully",
            color: "success",
          });

          this.deleteModal = false
        }, 200)
      } catch (err) {
        this.$vs.notify({
          title: "Failed",
          text: "Failed to delete discount rule.",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      }
    },
    openPopupHandler(index, mode) {
      if (mode === 'delete') {
        this.deleteModal = true
      } else {
        this.createUpdateModal = true
      }

      this.selectedId = index
      this.details = cloneDeep(this.rules[index])
    },
    clearFields() {
      this.details = {
        discount_title: "",
        discount_price: "",
        min_qty: 0,
        max_qty: 0,
        minimum_purchase_amount: 0,
        display_message_min: "",
        display_message_max: "",
        discount_type: "percentage",
        discount_value: undefined,
        cost_price: undefined,
        fresh_price: undefined
      }
      this.selectedId = ""
    }
  },
  watch: {
    deleteModal: function(isOpen) {
      if (!isOpen) this.clearFields()
    },
    createUpdateModal: function(isOpen) {
      if (!isOpen) this.clearFields()
      if (isOpen) {
        this.$nextTick(() => {
          this.errors.clear()
        })
      }
    }
  }
}
</script>

<style lang="scss">
.custom-input {
  margin-bottom: 20px;

  .vue-daterange-picker > .form-control {
    padding: .7rem !important;
  }
}

.custom-input label,
.custom-label {
  display: block;
  padding: 5px 0;
  font-size: 12px;
}

.rule-create-update-modal {
  .vs-popup {
    width: 800px !important;
  }
}
</style>
